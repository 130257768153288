import React from 'react';
// import Button from './Button';
import HeroImage from '../svg/HeroImage';
import Button from './Button';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const HeroHome = () => {
  return (
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Requisitioning <br />
            Made Easy
          </h1>
          <h4 className="text-xl lg:text-2xl mt-6 my-10 font-bold">
            Connect with the Right Buyers.
          </h4>
          <Button size="xl">
            <AnchorLink to="#form" className="text-white hover:text-white">
              Get Started
            </AnchorLink>
          </Button>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
  );
};

export default HeroHome;
