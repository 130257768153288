import React from 'react';
import BigCTA from '../components/BigCTA';

import Features from '../components/Features';
import HeroHome from '../components/HeroHome';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import Subscribe from '../components/MailChimpForm';
import SEO from '../components/seo';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
// import Testimonials from '../components/Testimonials';
// import SvgCharts from '../svg/SvgCharts';
import HIW1 from '../images/plan2.png';
import HIW2 from '../images/optimise.png';
import HIW3 from '../images/hand-shake.png';

export default () => (
  <Layout>
    <SEO title="Requzy" />
    <HeroHome />
    <Subscribe />
    <Features />

    <SplitSection
      id="howitworks"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Plan Your Produce</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Requzy™ allows you to map out your produce cycle and allocations for the entire season.
          </p>
        </div>
      }
      secondarySlot={<img src={HIW1} alt="" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">Optimise Your Business</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Keep Track of your buyers, shipments and payments. Execute your business with confidence
          </p>
        </div>
      }
      secondarySlot={<img src={HIW2} alt="" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Discover New Markets</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Widen the horizon of your market and increase your sales potential. <br />
            <strong>Sell more, Faster</strong>.
          </p>
        </div>
      }
      secondarySlot={<img src={HIW3} alt="" />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="2800" secondaryText="Man hours saved" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="-45%" secondaryText="Time to Requisition" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Attention to Customers" />
          </div>
        </div>
      </div>
    </section>
    {/* <Testimonials /> */}
    <BigCTA
      mainHeading="Ready to grow your business?"
      subHeading="Experience growth, while significantly reducing cost and time to make or process a requisition."
      cta="Get Invited"
      to="/sign-up"
    />
  </Layout>
);
