import React from 'react';
import Button from './Button';

const BigCTA = ({ mainHeading, subHeading, cta, to }) => {
  return (
    <div>
      <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-5xl font-semibold">{mainHeading}</h3>
        <p className="mt-8 text-xl font-light">{subHeading}</p>
        <p className="mt-8">
          <Button size="xl" to={to}>
            {cta}
          </Button>
        </p>
      </section>
    </div>
  );
};

export default BigCTA;
