import React from 'react';
import Card from './Card';

const Features = () => {
  return (
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Farm to Fork</p>
              <p className="mt-4">
                Streamlined requisitions and approved processes allow you to stay on top of supply.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Scalable & Secure</p>
              <p className="mt-4">
                Suitable for single suppliers or an enterprise, Requzy™ scales with your demand.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Intelligent & Smart</p>
              <p className="mt-4">
                Keep all the stakeholders connected, Report analyse and automate with ease.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
